<style>
.el-progress-bar {
    padding-right: 0;
}
</style>
<template>
    <div class="audio-content">
        <audio
            :src="this.$store.state.ossAudioUrl + list[play_index].fileUrl"
            controls
            ref="audio"
            style="display: none"
        ></audio>
        <div class="play-box">
            <div class="video">
                <div class="conttrol-box">
                    <div class="control">
                        <i
                            class="iconfont icon-bofangjian"
                            @click="audio_play"
                            v-if="!status"
                        ></i>
                        <i
                            class="iconfont icon-zanting"
                            @click="audio_pause"
                            v-else
                        ></i>
                    </div>
                    <div class="timer">
                        <span>{{ currentTime }}</span
                        >/<span>{{ duration }}</span>
                    </div>
                </div>
                <div class="progress">
                    <el-progress
                        :percentage="percentage"
                        :format="format"
                    ></el-progress>
                </div>
            </div>
        </div>
        <div class="audio_group">
            <div
                class="audio_list"
                v-for="(item, index) in list"
                :key="index"
                @dblclick="changeAudio(index)"
            >
                <div class="audio_name">
                    {{ item.fileName }}
                </div>
                <div class="audio_status">
                    <span v-if="index == play_index">正在播放</span>
                    <span v-else>等待播放</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AudioPlay",
    props: ["list"],

    data() {
        return {
            duration: "",
            duration_arr: [],
            currentTime: "0.0000",
            currentTime_arr: [],
            timer: null,
            status: false,
            percentage: 0,
            play_index: 0,
        };
    },
    created() {
        this.list[0].status = true;
        // console.log(1);
    },
    mounted() {
        // console.log(2);
        let audio_dom = this.$refs.audio;
        let _this = this;
        audio_dom.addEventListener("loadedmetadata", function (event) {
            console.log("准备好了");
            let duration = audio_dom.duration;
            _this.duration = duration;
            _this.duration_arr = duration.toString().split(".");
            _this.audio_play();
        });
        audio_dom.addEventListener("pause", () => {
            //暂停开始执行的函数
            console.log("暂停播放");
            _this.status = false;
            clearInterval(_this.timer);
        });
        audio_dom.addEventListener("ended", () => {
            //播放结束执行的函数
            console.log("播放结束");
            _this.percentage = 100;
            _this.currentTime = _this.duration;
        });
        audio_dom.addEventListener("play", () => {
            _this.status = true;
            clearInterval(_this.timer);
            _this.timer = setInterval(() => {
                let currentTime = audio_dom.currentTime;
                _this.currentTime = currentTime.toString();
                _this.currentTime_arr = _this.currentTime.split(".");
                _this.percentage = (currentTime / _this.duration) * 100;
            }, 100);
        });
    },
    watch: {
        list(val1, val2) {
            console.log(val1);
            console.log(val2);
        },
    },
    methods: {
        format() {
            return "";
        },
        audio_play() {
            let audio_dom = this.$refs.audio;
            audio_dom.play();
            console.log(1);
        },
        audio_pause() {
            this.$refs.audio.pause();
            clearInterval(this.timer);
        },
        // 切换播放源
        changeAudio(index) {
            this.audio_pause();
            this.play_index = index;
            this.audio_play();
        },
    },
};
</script>

<style lang="less" scoped>
.audio-content {
    background-color: #000;
    padding: 10px;
    color: #fff;
}
.conttrol-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.video {
    background-color: #000;
}
.audio_list {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>