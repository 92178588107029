<style lang="less">
.video_box {
    min-width: 0 !important;
    width: 178px !important;
    height: 128px !important;
    box-sizing: border-box;
}
</style>
<style lang="less" scoped>
.script-content {
    padding: 10px;
    background-color: #fff;
}
.turn {
    padding: 0 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .turn-box {
        flex: 1;
        height: 0;
    }
    .turn-btns {
        padding: 20px 0;
    }
}
</style>
<template>
    <div class="create-content">
        <el-drawer title="文本转语音" :size="700" :visible.sync="showTurn">
            <div class="turn">
                <div class="turn-box">
                    <TURN
                        ref="turn"
                        :trun_txt="select_txt"
                        :key="Math.random()"
                    />
                </div>
                <div class="turn-btns">
                    <el-button type="primary" size="small" @click="git_audio"
                        >保存并添加</el-button
                    >
                </div>
            </div>
        </el-drawer>
        <!-- <AUDIO /> -->
        <el-dialog
            title="音频预览"
            :visible.sync="preview_dialog"
            width="30%"
            @close="preview_close"
        >
            <AUDIO :list="preview_list" ref="audio" />
            <span slot="footer" class="dialog-footer">
                <el-button @click="preview_dialog = false">关闭</el-button>
            </span>
        </el-dialog>
        <div class="header content">
            <div class="title">
                <div @click="$goBack" class="back">
                    <i class="el-icon-arrow-left"></i>
                    创建脚本
                </div>
            </div>
            <div class="script_data">
                <div class="data_left">
                    <ul class="data_gruop">
                        <li>脚本名称: {{ script.title }}</li>
                        <li>
                            画布: {{ script.canvas.width }} :
                            {{ script.canvas.height }}
                        </li>
                        <li>
                            分辨率: {{ script.rate.x }} : {{ script.rate.y }}
                        </li>
                        <li>
                            <i
                                class="el-icon-edit"
                                @click="showAddScript = true"
                            ></i>
                        </li>
                    </ul>
                </div>
                <div class="data_right">
                    <ul class="edit_btn_row">
                        <li>
                            <el-dropdown>
                                <span class="el-dropdown-link">
                                    添加<i
                                        class="el-icon-arrow-down el-icon--right"
                                    ></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="addGruop"
                                        >添加分组</el-dropdown-item
                                    >
                                    <el-dropdown-item
                                        >添加分镜</el-dropdown-item
                                    >
                                </el-dropdown-menu>
                            </el-dropdown>
                        </li>
                        <li>
                            <el-button
                                type="primary"
                                size="small"
                                v-if="script.id != undefined"
                                @click="edit"
                                >修改</el-button
                            >
                            <el-button
                                v-else
                                type="primary"
                                size="small"
                                @click="submit"
                                >保存</el-button
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 分组 -->
        <div class="script-content">
            <div
                class="script-list content"
                v-for="(item, index) in script.gruop"
                :key="index"
            >
                <div class="list-top">
                    <div class="title">
                        <i class="el-icon-arrow-down"></i>
                        <div
                            class="edit"
                            contenteditable="true"
                            ref="edit_title_box"
                            @blur="up_title(index)"
                            @keydown.enter.prevent
                        >
                            {{ item.title }}
                        </div>
                    </div>
                    <!-- <div class="up_name" v-if="item.setName == 1">
                        <el-input
                            placeholder="请输入分组标题"
                            v-model="item.title"
                            size="mini"
                            clearable
                            @change="up_group_title(item)"
                            @blur="up_group_title(item)"
                        >
                        </el-input>
                        <el-button
                            size="mini"
                            type="primary"
                            @click="setName(index, gruop_title)"
                            >保存</el-button
                        >
                    </div>
                    <div class="gruop-title" v-else>
                        <i class="el-icon-arrow-down"></i>
                        <span>{{ item.title }}</span>
                        <i class="el-icon-edit" @click="rename(index)"></i>
                    </div> -->
                    <div class="script-edit">
                        <el-dropdown>
                            <span class="el-dropdown-link">
                                移动<i
                                    class="el-icon-arrow-down el-icon--right"
                                ></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    @click.native="moveGruopUp(index)"
                                    >上移</el-dropdown-item
                                >
                                <el-dropdown-item
                                    @click.native="moveGruopDown(index)"
                                    >下移</el-dropdown-item
                                >
                                <el-dropdown-item
                                    @click.native="delGruop(index)"
                                    >删除</el-dropdown-item
                                >
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
                <div class="gruop-row">
                    <div class="gruop-row-tis" @click="turnChange(index)">
                        <ul class="txt-box">
                            <li>多语音</li>
                            <li>AI配音</li>
                        </ul>
                    </div>
                    <!-- <div class="describe_box"></div> -->
                    <div class="gruop-content">
                        <el-input
                            class="textarea"
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="item.txt"
                            resize="none"
                        >
                        </el-input>
                    </div>
                </div>
                <div class="gruop-row">
                    <div class="gruop-row-tis">
                        <ul class="txt-box" @click="openImport(index, 1)">
                            <i class="el-icon-upload"></i>
                            <div>添加素材</div>
                        </ul>
                    </div>
                    <!-- <div class="describe_box">
                        <div class="describe_title">场景描述</div>
                        <div class="textarea_box">
                            <el-input
                                type="textarea"
                                placeholder="请输入内容"
                                v-model="textarea"
                            >
                            </el-input>
                        </div>
                    </div> -->
                    <div class="gruop-content">
                        <div class="source-box">
                            <div
                                class="source-item"
                                v-for="(vlist, vindex) in item.video"
                                :key="vindex"
                            >
                                <el-popover
                                    placement="right"
                                    popper-class="video_box"
                                    trigger="hover"
                                    @show="
                                        vPlay(
                                            'video' + index + vindex,
                                            vlist.fileUrl
                                        )
                                    "
                                    @hide="vStop('video' + index + vindex)"
                                >
                                    <video
                                        :ref="'video' + index + vindex"
                                        src=""
                                        width="100%"
                                        height="100%"
                                    ></video>
                                    <div
                                        class="img-box"
                                        style="
                                            height: 100px;
                                            background-color: #ddd;
                                        "
                                        slot="reference"
                                    >
                                        <el-image
                                            style="width: 100%; height: 100%"
                                            :src="vlist.coverUrl"
                                            fit="contain"
                                        ></el-image>
                                    </div>
                                </el-popover>

                                <i
                                    class="el-icon-close"
                                    @click="del_video(item, vindex, 1)"
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="gruop-row">
                    <div class="gruop-row-tis">
                        <ul class="txt-box" @click="openImport(index, 2)">
                            <i class="el-icon-upload"></i>
                            <div>添加音频</div>
                        </ul>
                    </div>
                    <div class="gruop-content">
                        <div
                            class="source-box"
                            @click="open_preview(index, item.audio)"
                        >
                            <div
                                class="source-item"
                                v-for="(alist, aindex) in item.audio"
                                :key="aindex"
                            >
                                <img src="@/assets/images/audio.jpeg" alt="" />
                                <i
                                    class="el-icon-close"
                                    @click.stop="del_video(item, aindex, 2)"
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            title="导入视频"
            :visible.sync="showImport"
            width="1100px"
            :before-close="closeImport"
        >
            <div style="height: 600px">
                <MATERIAL
                    v-if="showImport"
                    ref="daoru"
                    :type="2"
                    :sourec_type="contentType"
                />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showImport = false">取 消</el-button>
                <el-button type="primary" @click="getImportData"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <el-dialog
            title="设置基础参数"
            :visible.sync="showAddScript"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            width="490px"
            :before-close="closeAddScript"
        >
            <ADD_SCRIPT ref="script_set" />

            <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="showAddScript = false">取 消</el-button> -->
                <el-button type="primary" @click="getScriptSet"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import TURN from "@/components/work/turn";
import AUDIO from "@/components/medium/audio_play";
import MATERIAL from "@/components/work/sourceMaterial";
import ADD_SCRIPT from "@/components/script/addScript";
// import FOLD_PANEL from "@/components/fold_panel";
import { createScript, getScriptInfo, editScript } from "@/api/script";

import { gitAudio } from "@/api/engi";
export default {
    name: "crateScript",
    components: {
        MATERIAL,
        ADD_SCRIPT,
        TURN,
        AUDIO,
        // FOLD_PANEL,
    },
    data() {
        return {
            preview_dialog: false,
            preview_list: [],
            showTurn: false,
            contentType: "", //素材格式
            mode: "",
            showAddScript: false,
            showImport: false,
            resName: false,
            gruop_title: "",
            gruopIndex: "", //操作分组索引
            scriptClass: "GenericScript", //固定 服务端需要
            select_txt: "",
            script: {
                title: "镜头一", //脚本标题
                scriptClass: "GenericScript",
                canvas: {
                    //画布
                    width: "",
                    height: "",
                },
                rate: {
                    //分辨率
                    x: "",
                    y: "",
                },
                gruop: [
                    {
                        title: "分组0", // 分组名称
                        txt: "", //语音文字
                        video: [], //视频数组
                        audio: [], //音频数组
                    },
                ],
            },
            gruop: [
                {
                    title: "", // 分组名称
                    txt: "", //语音文字
                    video: [], //视频数组
                    audio: [], //音频数组
                },
            ],
        };
    },
    created() {
        const getData = this.$route.query;
        console.log(getData);
        switch (getData.mode) {
            case "1":
                // 修改
                getScriptInfo(getData.id).then((res) => {
                    let data = res.data;
                    this.script.title = data.title;
                    this.script.canvas = JSON.parse(data.canvas);
                    this.script.rate = JSON.parse(data.rate);
                    this.script.gruop = JSON.parse(data.lensGroup);

                    console.log(this.script.gruop);
                    this.script.id = getData.id;
                });
                break;
            case "2":
                // 创建
                this.script.title = getData.title;
                this.script.canvas = getData.canvas;
                this.script.rate = getData.rate;
                break;

            default:
                this.showAddScript = true;
                break;
        }
    },
    mounted() {},

    methods: {
        keyEnter(e) {
            console.log(e);
        },
        up_title(index) {
            const dom = this.$refs.edit_title_box[index];
            this.script.gruop[index].title = dom.innerText;
            console.log(dom.innerText);
            console.log(index);
        },
        up_group_title(item) {
            console.log(item);
            item.setName = 0;
        },
        open_preview(index, list) {
            // console.log(list);
            this.preview_dialog = true;
            this.preview_list = list;
        },
        preview_close() {
            this.$refs.audio.audio_pause();
        },
        turnChange(group_index) {
            // console.log(group_index);
            this.select_txt = this.script.gruop[group_index].txt;
            this.group_index = group_index;
            this.showTurn = true;
        },
        git_audio() {
            let audioOss = this.$store.state.audio;
            let turn_data = this.$refs.turn.turn;
            if (!turn_data.text) {
                this.$message.error("请输入需要文本类容");
                return;
            }
            // console.log();
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            // console.log();
            gitAudio(turn_data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.showModel = true;

                    console.log(res);
                    let file_data = res.data.fileInfo;
                    console.log(this.group_index);
                    console.log(this.script.gruop);
                    this.script.gruop[this.group_index].audio.unshift(
                        file_data
                    );
                    this.showTurn = false;
                    // this.audio = audioOss + res.data.fileInfo.fileUrl;
                    // this.audio = audioOss + res.data.fileInfo.fileUrl;
                    // this.$refs.audio.src = this.audio;
                    // console.log(this.audio.fileUrl);
                } else {
                    this.$message.error("对不起，文本转语音失败！");
                }
                loading.close();
            });
        },
        vStop(item) {
            this.$refs[item][0].pause();
            this.$refs[item][0].src = "";
        },

        vPlay(item, url) {
            // console.log(item);
            // item[0].play()
            this.$refs[item][0].src = this.$store.state.ossVideoUrl + url;
            this.$refs[item][0].play();
            // console.log(this.$refs[item][0].src);
        },
        getImportData() {
            // let arr = [];
            let arr = this.$refs.daoru.select_data;
            // getSelect.forEach((item) => {
            //     if (item.checked) {
            //         arr.push(item);
            //     }
            // });
            console.log(arr);
            // console.log(this.contentType);
            switch (this.contentType) {
                case 1:
                    console.log("视频导入");
                    this.script.gruop[this.gruopIndex].video.unshift(...arr); //导入操作原有数组
                    break;

                case 2:
                    this.script.gruop[this.gruopIndex].audio.unshift(...arr);
                    break;

                default:
                    break;
            }
            this.showImport = false;
        },
        openImport(index, contentType) {
            this.gruopIndex = index;
            this.contentType = contentType;
            this.showImport = true;
        },
        closeImport() {
            this.showImport = false;
        },
        getScript() {},
        // 提交脚本
        submit() {
            let send = {
                id: this.script.id,
                title: this.script.title,
                scriptClass: "GenericScript",
                canvas: JSON.stringify(this.script.canvas),
                rate: JSON.stringify(this.script.rate),
                lensGroup: JSON.stringify(this.script.gruop),
            };
            createScript(send).then((res) => {
                if (res.code == 200) {
                    this.$message.success(res.msg);
                    this.$router.push("/home/script/myscript");
                }
            });
            //   let arr = Array(this.script.canvas);
            //   console.log(JSON.stringify(arr));
        },
        edit() {
            let send = {
                id: this.script.id,
                title: this.script.title,
                scriptClass: "GenericScript",
                canvas: JSON.stringify(this.script.canvas),
                rate: JSON.stringify(this.script.rate),
                lensGroup: JSON.stringify(this.script.gruop),
            };
            editScript(send).then((res) => {
                if (res.code == 200) {
                    this.$message.success(res.msg);
                    // this.$router.push("/home/script/scriptlibrary");
                } else {
                    this.$message.error("修改失败");
                }
            });
        },
        closeAddScript() {
            this.showAddScript = false;
        },
        rename(i) {
            this.$set(this.script.gruop[i], "setName", 1);
        },
        moveGruopUp(i) {
            // 上移动
            if (i == 0) {
                return;
            }
            // console.log("上移动");
            let inx = i - 1;
            // 上一组数据
            let upData = this.script.gruop[inx];
            console.log(upData);
            //   this.script.gruop[inx] = this.script.gruop[i];
            this.$set(this.script.gruop, inx, this.script.gruop[i]);
            //   this.script.gruop[i] = upData;
            this.$set(this.script.gruop, i, upData);
            //   console.log(this.script.gruop);
        },
        moveGruopDown(i, type) {
            // 下移动
            let len = this.script.gruop.length - 1;
            if (i == len) {
                return;
            }
            let inx = i + 1;
            let upData = this.script.gruop[inx];
            //   this.script.gruop[inx] = this.script.gruop[i];

            this.$set(this.script.gruop, inx, this.script.gruop[i]);

            //   this.script.gruop[i] = upData;
            //   console.log(this.script.gruop);
            this.$set(this.script.gruop, i, upData);
        },
        delGruop(index) {
            let test = this.script.gruop.splice(index, 1);
            console.log(test);
        },
        addGruop() {
            let gruop = {
                title: "", // 分组名称
                txt: "", //语音文字
                video: [], //视频数组
                audio: [], //音频数组
            };
            this.script.gruop.push(gruop);
            let num = this.script.gruop.length - 1;
            let newName = "分组" + num;
            gruop.title = newName;
            //   this.setName(num, newName);
        },
        setName(index, name) {
            let i = index;
            this.script.gruop[i].title = name;
            this.script.gruop[i].setName = 0;
            this.$set(this.script.gruop[i], "setName", 0);
        },
        del_video(item, index, isType) {
            let type_name = isType == 1 ? "视频" : "音频";

            this.$confirm(`是否确认删除此 ${type_name} `, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                if (isType == 1) {
                    item.video.splice(index, 1);
                } else {
                    item.audio.splice(index, 1);
                }
                this.$message({
                    type: "success",
                    message: "删除成功!",
                });
            });
        },
        getScriptSet() {
            let script_set_data = this.$refs.script_set;
            // console.log(script_set_data);
            this.script.canvas.width = script_set_data.add.canvas.width;
            this.script.canvas.height = script_set_data.add.canvas.height;
            this.script.rate.x = script_set_data.add.rate.x;
            this.script.rate.y = script_set_data.add.rate.y;
            this.script.title = script_set_data.add.title || "新建脚本";
            this.showAddScript = false;
        },
    },
};
</script>

<style lang="less" scoped>
.content {
    background-color: #fff;
    box-shadow: 0 0 6px #f3f3f3;
    margin-bottom: 10px;
}
.header {
    .title {
        padding: 20px;
        .back {
            cursor: pointer;
        }
    }
    .script_data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px 10px 20px;
    }
    .data_gruop {
        display: flex;
        font-size: 12px;
        color: #8597b2;
        // justify-content: space-between;
        li {
            margin-right: 30px;
        }
    }
    .edit_btn_row {
        display: flex;
        align-items: center;
        li {
            margin-left: 30px;
        }
    }
}
.list-top {
    background: linear-gradient(180deg, #f7f8fa 4%, #e2e7ee 99%);
    display: flex;
    justify-content: space-between;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;

    .title {
        display: flex;
        flex: 1;
        align-items: center;
        .edit {
            // border: 1px solid #d3d3d3;
            min-width: 200px;
            padding: 0 10px;
            margin-left: 10px;
            border-radius: 4px;
            height: 26px;
            line-height: 26px;
            &:focus {
                outline: #d3d3d3 solid 1px;
            }
        }
    }
    .gruop-title {
        i {
            margin: 0 10px;
        }

        /deep/ .el-icon-arrow-down {
            cursor: pointer;
        }
    }
}

.gruop-row + .gruop-row {
    border-top: 2px solid #f6f9ff;
}
.gruop-row {
    display: flex;
    padding: 10px;
    height: 120px;
    .gruop-row-tis {
        flex: 0 0 100px;
        background-color: #f5f6f8;
        border-radius: 5px;
        .txt-box {
            height: 100%;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #8597b2;
        }
    }
    .describe_box {
        flex: 0 0 300px;
        background-color: #f5f6f8;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .describe_title {
            padding: 6px 0;
        }
        .textarea_box {
            flex: 1;
        }
    }
    .gruop-content {
        // padding: 10px 0 10px 10px;
        flex: 1;
        padding-left: 10px;
        width: 0;
        overflow: auto hidden;
        .textarea,
        /deep/ .el-textarea__inner {
            height: 100%;
            //   width: 100%;
        }
    }
}

/deep/ .el-icon-edit {
    color: #479ebe;
    cursor: pointer;
}
.gruop-content {
    .source-box {
        display: flex;
        height: 100%;
        .source-item {
            flex: 0 0 150px;
            padding: 0px 3px;
            position: relative;
            padding-right: 20px;
            .el-icon-close {
                position: absolute;
                right: 5px;
                top: -3px;
                font-size: 12px;
                cursor: pointer;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.up_name {
    display: flex;
}
</style>